import { createSlice } from "@reduxjs/toolkit";

const home = createSlice({
    name: 'home',
    initialState: {
        filters: {
            date: {
                startDate: new Date(),
                endDate: new Date()
            },
        },
        slides: [
            'https://granbellhotel.lk/wp-content/uploads/2022/04/Standard-Room-1350x750-2.jpg',
        ],
        logo: 'https://i.ibb.co/j6Fk3Z7/image.png',
        rooms: [
            {
                title: 'AC extra single AC extra single AC extra single AC extra single',
                media: [
                    'https://granbellhotel.lk/wp-content/uploads/2022/04/Standard-Room-1350x750-2.jpg',
                ],
                activePrice: 1500,
            },
            {
                title: 'AC extra single',
                media: [
                    'https://granbellhotel.lk/wp-content/uploads/2022/04/Standard-Room-1350x750-2.jpg',
                ],
                activePrice: 1500,
            },
            {
                title: 'AC extra single',
                media: [
                    'https://granbellhotel.lk/wp-content/uploads/2022/04/Standard-Room-1350x750-2.jpg',
                ],
                activePrice: 1500,
            },
            {
                title: 'AC extra single',
                media: [
                    'https://granbellhotel.lk/wp-content/uploads/2022/04/Standard-Room-1350x750-2.jpg',
                ],
                activePrice: 1500,
            },
            {
                title: 'AC extra single',
                media: [
                    'https://granbellhotel.lk/wp-content/uploads/2022/04/Standard-Room-1350x750-2.jpg',
                ],
                activePrice: 1500,
            },
            {
                title: 'AC extra single AC extra single AC extra single AC extra single',
                media: [
                    'https://granbellhotel.lk/wp-content/uploads/2022/04/Standard-Room-1350x750-2.jpg',
                ],
                activePrice: 1500,
            },
        ]
    },
    reducers: {
        setReservationDate: ((state, action) => {
            state.filters.date = action.payload;
        })
    }
});

export const {setReservationDate} = home.actions;
export default home.reducer;