import { Box, Divider, Paper, InputBase, Typography, Button, ImageList, ImageListItem, Chip, ImageListItemBar, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Datepicker from "react-tailwindcss-datepicker";
import {setReservationDate} from '../features/homeSlice';
import moment from 'moment/moment';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import _ from 'lodash';

export default function Home() {
    const dispatch = useDispatch();
    const slides = useSelector((state) => state.home.slides);
    const reservationDate = useSelector((state) => state.home.filters.date);
    const logo = useSelector((state) => state.home.logo);
    const rooms = useSelector((state) => state.home.rooms);
    return(
        <Box sx={{ background: '#EDEDED', minHeight: '100vh' }}>
            <Box sx={{ maxWidth: 1600, backgronud: 'red', mx: 'auto', background: 'white', paddingX: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingY: 2, }}>
                    <Box component="img" src={logo} height={60}></Box>
                    <Button size="medium" sx={{ borderRadius: 10, textTransform: 'capitalize', height: 40, mx: 2 }}>Properties</Button>
                    <Button size="medium" sx={{ borderRadius: 10, textTransform: 'capitalize', height: 40, mx: 2 }}>Attractions</Button>
                    <Button size="medium" sx={{ borderRadius: 10, textTransform: 'capitalize', height: 40, mx: 2 }}>Popular</Button>
                    <Box sx={{ flex: 1 }}></Box>
                    <Button variant="contained" size="medium" disableElevation sx={{ borderRadius: 10, height: 40, textTransform: 'capitalize', }}>Sign up</Button>
                    <Button variant="contained" size="medium" disableElevation sx={{ borderRadius: 10, height: 40, textTransform: 'capitalize', ml: 2 }}>Sign in</Button>
                </Box>
                <Box sx={{ position: 'relative', height: 266 }}>
                    <Box sx={{ background: 'url('+slides[0]+')', backgroundPosition: 'center center', backgroundSize: 'cover', borderRadius: 7, padding: 10, textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ color: 'white', fontWeight: 700 }}>Book your stay with Trisper</Typography>
                        <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 700 }}>Book your stay with Trisper</Typography>
                    </Box>
                    <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'row', background: 'white', position: 'absolute', bottom: 0, left: 0, right: 0, maxWidth: 700, marginX: 'auto', borderRadius: 4 }}>
                        <Datepicker separator={"to"} minDate={new Date()} maxDate={new Date(moment().add(60, 'days').format('YYYY, MM, DD'))} primaryColor={"blue"} displayFormat={"DD/MM/YYYY"} value={reservationDate} onChange={(newValue) => dispatch(setReservationDate(newValue))} showShortcuts={false}/> 
                        <Divider orientation="vertical" flexItem />
                        <InputBase placeholder="Adult" sx={{ mx: 2 }} />
                        <Divider orientation="vertical" flexItem />
                        <InputBase placeholder="Child" sx={{ ml: 2 }} />
                        <Button variant="contained" disableElevation color="primary" sx={{ borderRadius: 3 }}>
                            <ArrowForwardRoundedIcon />
                        </Button>
                    </Paper>
                </Box>
                <Box>
                    <Typography variant="body1" sx={{ color: 'grey', fontWeight: 'bold', marginTop: 2, marginBottom: 1, marginLeft: 1 }}>Most popular rooms</Typography>
                    <ImageList variant="quilted" cols={4} gap={8} rowHeight={230}>
                        {
                            _.take(rooms, 6).map((item, index) => (
                                <ImageListItem  key={index} cols={index % 3 === 0 ? 1 : 1} rows={index % 3 === 0 ? 2 : 1}>
                                    <img component="img" src={item.media[0]} alt={item.title} loading="lazy" style={{ borderRadius: 16, objectFit: 'cover' }} />
                                    <ImageListItemBar position="bottom" sx={{ borderBottomRightRadius: 14, borderBottomLeftRadius: 14, background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)', }} title={<Chip label={item.title} sx={{ background: 'white', opacity: .37 }} />} />
                                </ImageListItem>
                            ))
                        }
                    </ImageList>
                </Box>
                <Box>
                    <Typography variant="body1" sx={{ color: 'grey', fontWeight: 'bold', marginTop: 4, marginBottom: 1, marginLeft: 1 }}>Todays best deal</Typography>
                    <Stack direction="row" spacing={2}>
                        {
                            _.take(rooms, 5).map((item, index) => (
                                <Stack key={index} sx={{ padding: 1, background: 'rgba(3, 136, 252, 0.2)', borderRadius: 5, maxWidth: 308.8 }}>
                                    <img component="img" src={item.media[0]} alt={item.title} loading="lazy" style={{ borderRadius: 16, aspectRatio: 1, objectFit: 'cover' }} />
                                    <Box sx={{ paddingY: 1, paddingX: 1, display: 'flex', flexDirection: 'column', flexWrap: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <Typography variant="subtitle2" noWrap>{item.title}</Typography>
                                        <Typography variant="subtitle2">from ৳ {item.activePrice}/night</Typography>
                                    </Box>
                                </Stack>
                            ))
                        }
                    </Stack>
                </Box>
                <Paper sx={{ display: 'flex', flexDirection: 'row', flex: 1, padding: 3, alignItems: 'center', marginY: 6, borderRadius: 4 }}>
                    <NewspaperIcon sx={{ fontSize: 56 }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flex: 1, marginX: 3 }}>
                        <Typography variant="subtitle2">Join in newsteller</Typography>
                        <Typography variant="body2">Do you want to get secret offers and best prices for amaizing days? Signup to join our newsteller</Typography>
                    </Box>
                    <Button variant="outlined" size="small" sx={{ borderRadius: 4, textTransform: 'capitalize' }}>Join newsteller</Button>
                </Paper>
                <Divider />
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row', }, paddingY: 3, justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6">Hotel Name</Typography>
                            <Typography variant="subtitle2">Hotel Moto hotel moto hotel moto</Typography>
                            <Typography variant="body2">Hotel address hotel address hotel address</Typography>
                            <Typography variant="body2">Google Map</Typography>
                            <Typography variant="body2">© AGAMiLabs</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                            <Typography variant="h6">Help</Typography>
                            <Typography variant="subtitle2">FAQ</Typography>
                            <Typography variant="body2">Customer Services</Typography>
                            <Typography variant="body2">How to guide</Typography>
                            <Typography variant="body2">Contact us</Typography>
                        </Box>
                </Box>
            </Box>
        </Box>
    )
}
//
